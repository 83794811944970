import { Component, Input } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { DateUtils, FormationEventLiteDTO } from 'common';

@Component({
  selector: 'hiji-mif-event-card',
  templateUrl: './mif-event-card.component.html',
  styleUrl: './mif-event-card.component.scss',
})
export class MifEventCardComponent {
  @Input() event: FormationEventLiteDTO;
  DateUtils: typeof DateUtils = DateUtils;
  string_to_slug = string_to_slug;
}
