<a class="text-body" routerLink="/search">
  <div class="bg-white border rounded-3">
    <div class="row g-1">
      <div class="col-auto d-flex align-items-center">
        <i
          class="fa-light fa-3x text-tertiary p-4 align-icon"
          [class.fa-search]="!manualHierarchicalManagement"
          [class.fa-plus]="manualHierarchicalManagement"
        ></i>
      </div>
      <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">
            {{
              manualHierarchicalManagement
                ? ('search-card.Ajouter un collaborateur à mon équipe' | translate)
                : ('search-card.Rechercher un collaborateur' | translate)
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
