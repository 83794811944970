<a class="text-body" routerLink="/my-profile">
  <div class="bg-white border rounded-3">
    <div class="row g-1">
      <div class="col-auto d-flex align-items-center">
        <i class="fa-light fa-camera fa-3x text-tertiary p-4 align-icon"></i>
      </div>
      <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">
            <b>{{ 'encourage-picture-card.Et si vous mettiez une photo ?' | translate }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <small>{{ 'encourage-picture-card.Cliquez ici pour mettre à jour votre photo' | translate }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
