<div class="chart-wrapper p-2" *ngIf="!error">
  <hiji-download-graph>
    <canvas style="max-height: 400px" baseChart [data]="lineChartData" [options]="lineChartOptions" [legend]="true" type="line"></canvas>
  </hiji-download-graph>
</div>
<div class="row mt-3" *ngIf="error">
  <div class="col">
    <ul class="errors">
      <li>{{ "user-actions.kpi.stats.Il n'y a pas suffisamment de données" | translate }}</li>
    </ul>
  </div>
</div>
