import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Ic2Module } from '@ic2/ic2-lib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { HijiCommonModule, TRANSLATE_CHILD_CONFIG } from 'common';
import { MomentModule } from 'ngx-moment';
import { FeedbackMessageModule } from '../fbs/feedback-message/feedback-message.module';
import { CertificationSessionCardComponent } from './certification-session-card/certification-session-card.component';
import { EncouragePictureCardComponent } from './encourage-picture-card/encourage-picture-card.component';
import { FbsReactionCardComponent } from './fbs-reaction-card/fbs-reaction-card.component';
import { FbsSolicitationCardComponent } from './fbs-solicitation-card/fbs-solicitation-card.component';
import { FeedbackCardComponent } from './feedback-card/feedback-card.component';
import { GroupCreateCardComponent } from './group-create-card/group-create-card.component';
import { GroupsCardComponent } from './groups-card/groups-card.component';
import { HomeClassicComponent } from './home-classic/home-classic.component';
import { HomeSkillsupComponent } from './home-skillsup/home-skillsup.component';
import { HomeWrapperComponent } from './home-wrapper/home-wrapper.component';
import { Inquiry360AnswerCardComponent } from './inquiry360-answer-card/inquiry360-answer-card.component';
import { Inquiry360CreateCardComponent } from './inquiry360-create-card/inquiry360-create-card.component';
import { ManagerInvitationCardComponent } from './manager-invitation-card/manager-invitation-card.component';
import { MifEvaCardComponent } from './mif-eva-card/mif-eva-card.component';
import { MifEventCardComponent } from './mif-event-card/mif-event-card.component';
import { MifFormationSessionCardComponent } from './mif-formation-session-card/mif-formation-session-card.component';
import { MifManagerExpectationsCardComponent } from './mif-manager-expectations-card/mif-manager-expectations-card.component';
import { MifParticipantEvaluationActionsCardComponent } from './mif-participant-evaluation-actions-card/mif-participant-evaluation-actions-card.component';
import { RejoindreCertificationCardComponent } from './rejoindre-certification-card/rejoindre-certification-card.component';
import { SearchCardComponent } from './search-card/search-card.component';
import { SurveyCardComponent } from './survey-card/survey-card.component';
import { TeamboardCardComponent } from './teamboard/teamboard-card/teamboard-card.component';
import { TeamboardCreateCardComponent } from './teamboard/teamboard-create-card/teamboard-create-card.component';
import { UserCardModule } from './user-card/user-card.module';

@NgModule({
  declarations: [
    HomeWrapperComponent,
    SurveyCardComponent,
    GroupCreateCardComponent,
    SearchCardComponent,
    FeedbackCardComponent,
    GroupsCardComponent,
    Inquiry360CreateCardComponent,
    Inquiry360AnswerCardComponent,
    EncouragePictureCardComponent,
    FbsReactionCardComponent,
    FbsSolicitationCardComponent,
    MifFormationSessionCardComponent,
    MifEvaCardComponent,
    MifManagerExpectationsCardComponent,
    RejoindreCertificationCardComponent,
    MifParticipantEvaluationActionsCardComponent,
    MifEventCardComponent,
    TeamboardCardComponent,
    TeamboardCreateCardComponent,
    CertificationSessionCardComponent,
    ManagerInvitationCardComponent,
    HomeWrapperComponent,
    HomeClassicComponent,
    HomeSkillsupComponent,
  ],
  imports: [
    FeedbackMessageModule,
    CommonModule,
    UserCardModule,
    FeedbackMessageModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HijiCommonModule,
    Ic2Module,
    TranslateModule.forChild(TRANSLATE_CHILD_CONFIG),
    RouterModule.forChild([]),
  ],
  exports: [ManagerInvitationCardComponent],
})
export class HomeModule {}
