import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EnvService, string_to_slug } from '@ic2/ic2-lib';
import { AuthService, ClientEnv, Config, DateUtils, HijiBundle, HomeDTO, HomeService, ModulesDTO, TeamMemberDTO } from 'common';

@Component({
  selector: 'hiji-home-wrapper',
  templateUrl: './home-wrapper.component.html',
  styleUrl: './home-wrapper.component.scss',
})
export class HomeWrapperComponent {
  data: HomeDTO = null;
  loading: boolean = true;
  modulesDTO: ModulesDTO;
  encouragePicture: boolean = false;
  string_to_slug = string_to_slug;

  constructor(
    private homeService: HomeService,
    private authService: AuthService,
    private router: Router,
    public envService: EnvService<Config>,
    public clientEnv: ClientEnv
  ) {
    this.modulesDTO = (<HijiBundle>this.authService.userBundle.data).modules;
    this.encouragePicture =
      (<HijiBundle>this.authService.userBundle.data).hijiUser.avatarExtension === null && !envService.configuration.hideHomeEncouragePictureCard;
  }

  ngOnInit(): void {
    this.homeService
      .getTasks()
      .defaultOnError()
      .execute((data) => {
        this.data = data;
        this.loading = false;
      });
  }

  /*getNbActions() {
    if (this.data === null) return 0;
    let nb = 0;
    if (this.data.me.campaignExists && this.isMoreThanOneMonth(this.data.me.lastAutoObservationDate)) nb++;
    nb += this.getTeam(true).length;
    nb += this.data.surveys.filter((s) => !s.answered).length;
    return nb;
  }*/

  isTodayOrPast(date: Date): boolean {
    if (date === null) return false;
    const today = new Date();
    return date.getUTCFullYear() <= today.getUTCFullYear() && date.getMonth() <= today.getMonth() && date.getDate() <= today.getDate();
  }

  getTeam(usersWithSomethingToDo: boolean): TeamMemberDTO[] {
    let teamToReturn: TeamMemberDTO[] = [];
    const hasTodo = (u: TeamMemberDTO) => {
      if (u.campaignExists && DateUtils.isCurrentMonth(u.lastExchangeDate)) return true;
      if (u.isTargetedByEMA && u.nextEMADate === null) return true;
      if (u.isTargetedByEMA && this.isTodayOrPast(u.nextEMADate)) return true;
      if (u.isTargetedByEMA && this.isTodayOrPast(u.nextObsOrAutoObsHintDateEMA)) return true;
      return false;
    };

    teamToReturn = this.data.team.filter((u) => {
      return usersWithSomethingToDo ? hasTodo(u) : !hasTodo(u);
    });
    teamToReturn.sort((a, b) => {
      if (a.isPDIAvailable) return -1;
      else return 1;
    });
    return teamToReturn;
  }

  iDontHaveATeamBoard() {
    if (!this.authService.userBundle.user) return false; //cas ou tu es déconnecté
    return this.data.teamBoards.filter((tb) => tb.manager === null || tb.manager.idUser === this.authService.userBundle.user.idUser).length === 0;
  }
}
