import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { string_to_slug } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { Ic2ToastrService, ManagerInvitationDTO, UserRelationService } from 'common';

@Component({
  selector: 'hiji-manager-invitation-card',
  templateUrl: './manager-invitation-card.component.html',
  styleUrls: ['./manager-invitation-card.component.scss'],
})
export class ManagerInvitationCardComponent implements OnInit {
  @Input()
  data: ManagerInvitationDTO;
  @Output()
  remove: EventEmitter<any> = new EventEmitter();

  string_to_slug = string_to_slug;
  acceptMode: boolean = false;
  loading: boolean = false;

  constructor(
    private router: Router,
    private userRelationService: UserRelationService,
    private ic2ToastrService: Ic2ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  accept() {
    this.loading = true;
    this.userRelationService
      .acceptInvitationManager(this.data.idUser)
      .onBusinessErrorCode('duplicated_relation', (err) => {
        this.ic2ToastrService.showDanger(this.translate.instant("home.card.manager-invitation.toast.L'invitation à déjà été acceptée"));
      })
      .onOtherErrorTypesUseDefault()
      .onErrorAlwaysDo(() => {
        this.loading = false;
      })
      .execute((data) => {
        this.loading = false;
        this.remove.emit();
      });
  }

  refuse() {
    this.loading = true;
    this.userRelationService
      .refuseInvitationManager(this.data.idUser)
      .onOtherErrorTypesUseDefault()
      .onErrorAlwaysDo(() => {
        this.loading = false;
      })
      .execute((data) => {
        this.loading = false;
        this.remove.emit();
      });
  }
}
