import { Component, Input, OnInit } from '@angular/core';
import { FeedbackDTO } from 'common';

@Component({
  selector: 'hiji-feedback-card',
  templateUrl: './feedback-card.component.html',
  styleUrls: ['./feedback-card.component.scss'],
})
export class FeedbackCardComponent implements OnInit {
  @Input() feedback: FeedbackDTO;

  constructor() {}

  ngOnInit(): void {}
}
