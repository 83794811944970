import { Component, Input } from '@angular/core';
import { EnvService, string_to_slug } from '@ic2/ic2-lib';
import { Config, DateUtils, FormationSessionLiteDTO } from 'common';

@Component({
  selector: 'hiji-mif-formation-session-card',
  templateUrl: './mif-formation-session-card.component.html',
  styleUrls: ['./mif-formation-session-card.component.scss'],
})
export class MifFormationSessionCardComponent {
  @Input()
  formationSession: FormationSessionLiteDTO;
  DateUtils: typeof DateUtils = DateUtils;
  string_to_slug = string_to_slug;

  constructor(public envConfig: EnvService<Config>) {}
}
