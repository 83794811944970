<a
  class="text-body"
  [routerLink]="
    survey.answered && !survey.draft
      ? ['/visualize/survey', survey.idCampaign, string_to_slug(survey.name | ms)]
      : ['/survey', survey.idCampaign, string_to_slug(survey.name | ms)]
  "
>
  <div class="bg-white border rounded-3">
    <div class="row g-1">
      <div class="col-auto d-flex align-items-center">
        <i class="fa-light fa-poll fa-3x text-tertiary p-4 align-icon"></i>
      </div>
      <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">
            <b>{{ survey.name | ms }}</b>
          </div>
        </div>

        <div class="row" *ngIf="survey.draft">
          <div class="col-auto text-success">
            <i class="fa-light fa-clipboard me-1"></i>
            {{ 'survey.card.En brouillon, à envoyer avant le' | translate }}
            <span class="fw-bold" [class.text-danger]="warningEndDate">{{ survey.endDate | localizedDate }}</span>
            <i class="fa-light fa-exclamation-triangle text-danger ms-2" *ngIf="warningEndDate"></i>
          </div>
        </div>
        <div class="row" *ngIf="!survey.draft">
          <div class="col">
            <small>{{ 'survey.card.Fin le' | translate }} {{ survey.endDate | localizedDate }}</small>
          </div>
        </div>
      </div>
      <div class="col-auto d-flex align-items-center" *ngIf="survey.answered">
        <i class="fa-light fa-check fa-3x text-success p-4"></i>
      </div>
    </div>
  </div>
</a>
