import { Component, Input, OnInit } from '@angular/core';
import { VizInquiryAnswerDTO } from 'common';

@Component({
  selector: 'hiji-visualize-sollicitation360-card',
  templateUrl: './visualize-sollicitation360-card.component.html',
  styleUrls: ['./visualize-sollicitation360-card.component.scss'],
})
export class VisualizeSollicitation360CardComponent implements OnInit {
  @Input()
  inquiryAnswer: VizInquiryAnswerDTO;

  constructor() {}

  ngOnInit(): void {}
}
