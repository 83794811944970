<div class="container-fluid bg-light-30p h-100 pb-3" style="overflow-y: hidden">
  <div class="row mt-3">
    <div class="col text-center">
      <h2>{{ 'certification.join-session.Rejoindre une session de certification ou de coaching' | translate }}</h2>
    </div>
  </div>

  <form>
    <div class="row justify-content-center mt-3">
      <div class="col-12 col-md-6 animate__animated animate__bounceInUp">
        <div class="bg-white border rounded-3 p-3">
          <div class="row">
            <div class="col">
              {{ 'certification.join-session.Entrez ci-dessous le code pour rejoindre la session' | translate }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <input class="form-control my-fs-5 text-center" style="min-height: 5rem" name="code" [disabled]="loading" [(ngModel)]="code" />
            </div>
          </div>
          <div class="row justify-content-end mt-3">
            <div class="col-auto">
              <button class="btn btn-primary" [disabled]="loading" type="submit" (click)="join()">
                <i class="fa-light fa-right-to-bracket me-2"></i>
                {{ 'certification.join-session.Rejoindre' | translate }}
                <i class="fa-light fa-spinner spinning ms-2" *ngIf="loading"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
