import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EnvService, L, string_to_slug } from '@ic2/ic2-lib';
import { AuthService, AvatarService, Config, HijiBundle, OrganisationType, ProfileService, UserLiteDTO, UserProfileDTO } from 'common';

@Component({
  selector: 'hiji-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  errorFile: string = null;
  public file: File = null;
  progressPercent = null;
  oldImgURL: any = null;
  loading: boolean = true;
  showConfirm: boolean = false;
  error: string = null;
  idUser: number;
  avatarExtension;
  profile: UserProfileDTO;
  managed: UserLiteDTO[] = [];
  @ViewChild('fileInput')
  fileInput: ElementRef;
  displayFunctionFront: boolean = true;
  hideHierarchyInMyProfile: boolean = false;
  showContactHelp: boolean = true;

  OrganisationType: typeof OrganisationType = OrganisationType;
  string_to_slug = string_to_slug;

  constructor(
    public avatarService: AvatarService,
    private profileService: ProfileService,
    private authService: AuthService,
    public env: EnvService<Config>
  ) {
    this.idUser = this.authService.userBundle.user.idUser;
    this.avatarExtension = (<HijiBundle>this.authService.userBundle.data).hijiUser.avatarExtension;
    this.showContactHelp = this.env.configuration.showContactHelp ?? true;
    this.hideHierarchyInMyProfile = this.env.configuration.hideHierarchyInMyProfile ?? false;
  }

  ngOnInit(): void {
    this.oldImgURL = this.avatarService.avatar;
    this.displayFunctionFront = (<HijiBundle>this.authService.userBundle.data).modules.displayFunctionFront;

    this.profileService.getMyProfil().subscribe(
      (data) => {
        this.profile = data;
        this.managed = [];
        this.managed.push(...data.managed);
        this.managed.push(...data.managedShared);
        this.loading = false;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  isShared(user: UserLiteDTO) {
    return this.profile.managedShared.find((u) => u.idUser === user.idUser) !== undefined;
  }

  handleFileInput(event) {
    let files: FileList = event.target.files;
    var fr = new FileReader();

    fr.onload = (e) => {
      // file is loaded
      var img = new Image();
      img.onload = () => {
        console.log(img.width + ' x ' + img.height);
        let dimensionsMin = { w: 150, h: 150 };
        if (img.width < dimensionsMin.w || img.height < dimensionsMin.h) {
          //dimensions minis
          this.errorFile = 'Votre fichier doit au minimum faire ' + dimensionsMin.w + 'x' + dimensionsMin.h;
        } else {
          this.file = files[0];
          console.log(this.file, this.file.size / 1024 / 1024 + 'MB', this.file.name.split('.').pop());
          this.upload();
        }
      };
      this.oldImgURL = this.avatarService.avatar;
      img.src = (e.target as any).result; // is the data URL because called with readAsDataURL
    };
    if (files[0] instanceof Blob) fr.readAsDataURL(files[0]);
    else console.error('File is not a blob', typeof files[0], files[0]);
  }

  upload() {
    this.errorFile = null;
    this.profileService
      .changeLogo()
      .withFile(this.file)
      .withUploadProgress((loaded, total) => {
        this.progressPercent = ((loaded / total) * 100).toFixed(0);
      })
      .defaultOnErrorAnd((err) => {
        this.progressPercent = null;
        this.errorFile = err.message;
      })
      .execute((data) => {
        this.progressPercent = null;
        if (!this.file || !this.file.name) {
          console.error(this.file);
        } else {
          let ext = this.file.name.substr(this.file.name.lastIndexOf('.') + 1);
          (<HijiBundle>this.authService.userBundle.data).hijiUser.avatarExtension = ext;
          this.avatarExtension = ext;
        }
        this.file = null;
        this.avatarService.reload();
        this.fileInput.nativeElement.value = '';
      });
  }
  deletePhoto() {
    this.profileService.deleteLogo().subscribe(
      (data) => {
        (<HijiBundle>this.authService.userBundle.data).hijiUser.avatarExtension = null;
        this.avatarExtension = null;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  getNodesFor(idOrganisationParent: number) {
    return this.profile.organisations.filter((o) => o.idParent === idOrganisationParent);
  }
}
