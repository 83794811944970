import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ic2Module } from '@ic2/ic2-lib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { HijiCommonModule, TRANSLATE_CHILD_CONFIG, UserTextassistModule } from 'common';
import { ObservableGestureModule } from '../../components/observable-gesture.module';
import { UserCardModule } from '../home/user-card/user-card.module';
import { MifSatisfactionModule } from '../mesure-impact-formation/participant/satisfaction/mif.satisfaction.module';
import { VisualizeSurveyChartComponent } from './visualize-survey/visualize-survey-chart/visualize-survey-chart.component';

@NgModule({
  declarations: [VisualizeSurveyChartComponent],
  imports: [
    CommonModule,
    ObservableGestureModule,
    UserCardModule,
    MifSatisfactionModule,
    UserTextassistModule,
    HijiCommonModule,
    Ic2Module,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule.forChild(TRANSLATE_CHILD_CONFIG),
  ],
  exports: [VisualizeSurveyChartComponent],
})
export class VisualizeModule {}
