<div class="container-fluid bg-light-30p h-100 fit-height d-flex flex-column">
  <div class="row" *ngIf="loading">
    <div class="col-12 text-center">
      <i class="fa-light fa-spinner spinning"></i>
    </div>
  </div>
  <ng-container *ngIf="!loading">
    <div class="row mt-2 align-items-center">
      <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 text-center">
        <label class="form-label" for="groupName" *ngIf="data.groupType === GroupType.GROUP">{{ "group.Nom de l'équipe" | translate }}</label>
        <input
          class="form-control form-control-lg"
          name="groupName"
          [(ngModel)]="data.groupName"
          type="text"
          placeholder="{{ 'group.Nom de l\'équipe' | translate }}"
          *ngIf="data.groupType === GroupType.GROUP"
        />
        <span *ngIf="data.groupType === GroupType.DIRECT_TEAM">{{ 'group.Équipe directe' | translate }}</span>
        <span *ngIf="data.groupType === GroupType.GLOBAL_TEAM">{{ 'group.Équipe globale' | translate }}</span>
      </div>
    </div>
    <div class="row text-danger" *ngIf="data.groupType !== GroupType.GROUP">
      <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 text-center">
        {{ "group.Vous ne pouvez pas éditer cette équipe car c'est une équipe auto généré" | translate }}
      </div>
    </div>

    <div class="row mt-2">
      <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
        {{ "group.Membres de l'équipe (\{\{ size \}\})" | translate : { size: data.users.length } }}
      </div>
    </div>
    <div class="container-fluid overflow-auto flex-grow-1 mt-1">
      <div class="row" *ngFor="let user of data.users">
        <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-2 bg-white rounded-3 pointer">
          <div class="row align-items-center">
            <div class="col-auto" routerLink="/user/{{ user.idUser }}/{{ string_to_slug(user.firstName + '-' + user.lastName) }}">
              <hiji-user-avatar
                style="width: 40px; height: 40px"
                [idUser]="user.idUser"
                [avatarExtension]="user.avatarExtension"
                [defaultBlueAvatar]="true"
              ></hiji-user-avatar>
            </div>
            <div class="col my-fs-2 my-fs-lg-2" routerLink="/user/{{ user.idUser }}/{{ string_to_slug(user.firstName + '-' + user.lastName) }}">
              <span>{{ user.firstName }} {{ user.lastName }}</span>
            </div>
            <div class="col-auto text-end" *ngIf="data.groupType === GroupType.GROUP">
              <i class="fa fa-trash text-danger-hover me-2" (click)="deleteUsers(user.idUser)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-4 justify-content-center align-items-center" *ngIf="data.groupType === GroupType.GROUP">
      <div class="col-auto">
        <button class="btn btn-secondary" (click)="goToGroup()">
          {{ 'group.Ajouter' | translate }}
          <i class="fa-light fa-light fa-plus ms-2"></i>
        </button>
      </div>
      <div class="col-auto" *ngIf="data.users.length > 0">
        <button class="btn btn-secondary" (click)="saveGroup()">
          {{ 'group.Enregistrer' | translate }}
          <i class="fa-light fa-light fa-save ms-2"></i>
        </button>
      </div>
    </div>
  </ng-container>
</div>
