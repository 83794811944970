<div class="row">
  <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
    <div class="bg-white border rounded-3 p-2">
      <div class="row mt-1">
        <div class="col-12 fw-bold text-center my-fs-md-4">{{ "user-actions.team.Nombre d'échanges dans le mois de son Équipe" | translate }}</div>
      </div>
      <a
        class="text-body"
        routerLink="/user/{{ member.idUser }}/{{ string_to_slug(member.firstName + '-' + member.lastName) }}"
        *ngFor="let member of data.userActionsEMADTO.team"
      >
        <div class="row mt-1">
          <div class="col-auto d-flex align-items-center justify-content-center">
            <hiji-user-avatar
              style="width: 40px; height: 40px"
              [idUser]="member.idUser"
              [avatarExtension]="member.avatarExtension"
              [defaultBlueAvatar]="true"
            ></hiji-user-avatar>
          </div>
          <div class="col-auto d-flex align-items-center justify-content-center" [style.color]="getColor(member)" *ngIf="getColor(member) !== null">
            <i class="fa-light fa-2x fa-person-simple me-2" style="min-width: 32px" *ngIf="member.rank === 1"></i>
            <i class="fa-light fa-2x fa-person-running me-2" style="min-width: 32px" *ngIf="member.rank === 2"></i>
            <i class="fa-light fa-2x fa-person-running-fast me-2" style="min-width: 32px" *ngIf="member.rank === 3"></i>
          </div>
          <div class="col align-self-center" [style.color]="getColor(member)">
            {{ member.firstName + ' ' + member.lastName }}
          </div>
          <div class="col align-self-center" *ngIf="member.isTargetedByEMA">
            {{ 'user-actions.team.\{\{nb\}\} échange(s) ce mois' | translate: { nb: member.nbEMAInCurrentMonth } }}
          </div>
          <div class="col align-self-center" *ngIf="!member.isTargetedByEMA">
            {{ 'user-actions.team.Non concerné' | translate }}
          </div>
          @if (envConfig.configuration.showEMAManagerExchangeWithTeamRepositoryDetails) {
            <div class="col align-self-center">
              <ul>
                @for (repo of member.list; track $index) {
                  <li>{{ repo.name | ms }}: {{ repo.nbExchange }}</li>
                }
              </ul>
            </div>
          }
        </div>
      </a>
    </div>
  </div>
</div>
