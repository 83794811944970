import { Component, OnInit } from '@angular/core';
import { L, string_to_slug } from '@ic2/ic2-lib';
import {
  AuthService,
  ExchangeItemDTO,
  FeedbackDTO,
  HijiBundle,
  ModulesDTO,
  VisualizeService,
  VizBilanDTO,
  VizInquiryAnswerDTO,
  VizInquiryDTO,
  VizSurveyDTO,
} from 'common';
enum AffichageEnum {
  VOIR_TOUT,
  FEEDBACK,
  EXCHANGE,
  SURVEY,
  BILAN,
  INQUIRY,
  INQUIRYANSWERS,
}
@Component({
  selector: 'hiji-visualize',
  templateUrl: './visualize.component.html',
  styleUrls: ['./visualize.component.scss'],
})
export class VisualizeComponent implements OnInit {
  loading: boolean = false;
  canLoadMore: boolean = true;
  nextMonthToLoad: Date = new Date();
  allData: { date: Date; data: any }[] = [];
  feedbacks: FeedbackDTO[] = [];
  exchanges: ExchangeItemDTO[] = [];
  surveys: VizSurveyDTO[] = [];
  bilans: VizBilanDTO[] = [];
  inquiries: VizInquiryDTO[] = [];
  inquiryAnswers: VizInquiryAnswerDTO[] = [];

  idUserLogged: number;
  affichage: AffichageEnum = AffichageEnum.VOIR_TOUT;
  AffichageEnum: typeof AffichageEnum = AffichageEnum;

  string_to_slug = string_to_slug;

  modulesDTO: ModulesDTO;
  constructor(private authService: AuthService, private visualizeService: VisualizeService) {
    this.idUserLogged = this.authService.userBundle.user.idUser;
    this.modulesDTO = (<HijiBundle>this.authService.userBundle.data).modules;
  }

  change(nouvelAffichage: AffichageEnum) {
    this.nextMonthToLoad = new Date();
    this.canLoadMore = true;
    this.allData = [];
    this.feedbacks = [];
    this.exchanges = [];
    this.surveys = [];
    this.bilans = [];
    this.inquiries = [];
    this.inquiryAnswers = [];
    this.affichage = nouvelAffichage;
    this.loadMore();
  }

  ngOnInit(): void {
    this.loadMore();
  }

  onScrollingFinished() {
    this.loadMore();
  }

  loadMore() {
    if (this.affichage === AffichageEnum.VOIR_TOUT && this.nextMonthToLoad !== null) {
      this.loading = true;
      this.visualizeService.load(this.nextMonthToLoad).subscribe(
        (data) => {
          const newdata = [];
          data.bilans.forEach((bilan) => {
            newdata.push({ date: bilan.comiteDate, data: bilan });
          });
          data.exchanges.forEach((exchange) => {
            newdata.push({ date: exchange.creationDate, data: exchange });
          });
          data.feedbacks.forEach((feedback) => {
            newdata.push({ date: feedback.feedback.creationDate, data: feedback });
          });
          data.surveys.forEach((survey) => {
            newdata.push({ date: survey.surveyResultsAvailableDate, data: survey });
          });
          data.inquiries.forEach((inquiry) => {
            newdata.push({ date: inquiry.startDate, data: inquiry });
          });
          data.inquiryAnswers.forEach((inquiry) => {
            newdata.push({ date: inquiry.answerDate, data: inquiry });
          });
          this.allData.push(...newdata);
          this.nextMonthToLoad = data.nextMonthToLoad;
          this.loading = false;
          if (this.allData.length < 10) this.loadMore();
        },
        (error) => {
          L.e(error);
          this.loading = false;
        }
      );
    } else if (this.affichage === AffichageEnum.FEEDBACK && this.canLoadMore) {
      this.loading = true;
      this.visualizeService.loadFeedbacks(this.feedbacks.length).subscribe(
        (data) => {
          this.loading = false;
          this.feedbacks.push(...data);
          this.canLoadMore = data.length === 10;
        },
        (err) => {
          this.loading = false;
          L.e(err);
        }
      );
    } else if (this.affichage === AffichageEnum.EXCHANGE && this.canLoadMore) {
      this.loading = true;
      this.visualizeService.loadExchanges(this.exchanges.length).subscribe(
        (data) => {
          this.loading = false;
          this.exchanges.push(...data);
          this.canLoadMore = data.length === 10;
        },
        (err) => {
          this.loading = false;
          L.e(err);
        }
      );
    } else if (this.affichage === AffichageEnum.BILAN && this.canLoadMore) {
      this.loading = true;
      this.visualizeService.loadBilans(this.bilans.length).subscribe(
        (data) => {
          this.loading = false;
          this.bilans.push(...data);
          this.canLoadMore = data.length === 10;
        },
        (err) => {
          this.loading = false;
          L.e(err);
        }
      );
    } else if (this.affichage === AffichageEnum.SURVEY && this.canLoadMore) {
      this.loading = true;
      this.visualizeService.loadSurvey(this.surveys.length).subscribe(
        (data) => {
          this.loading = false;
          this.surveys.push(...data);
          this.canLoadMore = data.length === 10;
        },
        (err) => {
          this.loading = false;
          L.e(err);
        }
      );
    } else if (this.affichage === AffichageEnum.INQUIRY && this.canLoadMore) {
      this.loading = true;
      this.visualizeService.loadInquiries(this.inquiries.length).subscribe(
        (data) => {
          this.loading = false;
          this.inquiries.push(...data);
          this.canLoadMore = data.length === 10;
        },
        (err) => {
          this.loading = false;
          L.e(err);
        }
      );
    } else if (this.affichage === AffichageEnum.INQUIRYANSWERS && this.canLoadMore) {
      this.loading = true;
      this.visualizeService.loadInquiryAnswers(this.inquiryAnswers.length).subscribe(
        (data) => {
          this.loading = false;
          this.inquiryAnswers.push(...data);
          this.canLoadMore = data.length === 10;
        },
        (err) => {
          this.loading = false;
          L.e(err);
        }
      );
    }
  }
}
