import { Component, Input } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { UserActionsDTO } from 'common';

@Component({
  selector: 'hiji-user-actions-teamboard',
  templateUrl: './user-actions-teamboard.component.html',
  styleUrl: './user-actions-teamboard.component.scss',
})
export class UserActionsTeamboardComponent {
  @Input()
  data: UserActionsDTO;
  @Input()
  me: boolean;
  string_to_slug = string_to_slug;
}
