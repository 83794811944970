import { Component, OnInit, signal } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { EnvService } from '@ic2/ic2-lib';
import { Config, RadarData } from 'common';
class testDTO {
  a: string;
}
@Component({
  selector: 'hiji-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
  data: RadarData[] = [];

  testSignal = signal(new testDTO());
  show = signal(false);

  test() {
    console.log(this.testSignal());
  }

  constructor(private swPush: SwPush, private env: EnvService<Config>) {
    //mock data
    this.data = [
      {
        name: 'blue',
        color: 'blue',
        background: true,
        values: [
          { axis: 'A', value: 70, color: '#f1b314', data: 1 },
          { axis: 'B', value: 70, color: '#f1b314', data: 2 },
          { axis: 'C', value: 70, color: '#f1b314', data: 3 },
          { axis: 'D', value: 70, color: 'cyan', data: 4 },
        ],
      },
      {
        name: 'red',
        color: 'red',
        background: true,
        values: [
          { axis: 'A', value: 50, color: '#f1b314', data: 1 },
          { axis: 'B', value: 50, color: '#f1b314', data: 2 },
          { axis: 'D', value: 50, color: 'cyan', data: 4 },
        ],
      },
    ];
  }

  ngOnInit(): void {}

  testBadge() {
    if ('setAppBadge' in navigator) {
      (<any>navigator).setAppBadge(2).catch((error) => {
        console.log('err setAppBadge', error);
      });
    }
  }

  testRegister() {
    this.swPush
      .requestSubscription({
        serverPublicKey: this.env.configuration.vapidPublicKey,
      })
      .then((sub) => console.log(sub.toJSON()))
      .catch((err) => console.error('Could not subscribe to notifications', err));
  }
}
