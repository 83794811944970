<div class="bg-white border rounded-3">
  <div class="row g-1">
    <div class="col-auto d-flex align-items-center">
      <i class="fa-light fa-glass-cheers text-primary fa-3x p-4 align-icon" *ngIf="reaction.reaction === FeedbackReaction.PARTY"></i>
      <i class="fa-light fa-heart fa-3x text-danger p-4 align-icon" *ngIf="reaction.reaction === FeedbackReaction.HEART"></i>
      <i class="fa-light fa-thumbs-up fa-3x text-tertiary p-4 align-icon" *ngIf="reaction.reaction === FeedbackReaction.LIKE"></i>
      <i class="fa-light fa-question fa-3x text-warning p-4 align-icon" *ngIf="reaction.reaction === FeedbackReaction.INTERROGATION"></i>
    </div>
    <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
      <div class="row">
        <div class="col-auto">
          <b>{{ reaction.firstName }} {{ reaction.lastName }} {{ 'fbs.reaction-card.a réagi à votre feedback' | translate }}</b>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <small>{{ reaction.message | truncate : 70 : true }}</small>
        </div>
      </div>
    </div>
  </div>
</div>
