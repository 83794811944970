import { Component, Input } from '@angular/core';
import { EnvService, string_to_slug } from '@ic2/ic2-lib';
import { Config, TeamMemberEMARecapDTO, UserActionsDTO } from 'common';

@Component({
  selector: 'hiji-user-actions-team',
  templateUrl: './user-actions-team.component.html',
  styleUrls: ['./user-actions-team.component.scss'],
})
export class UserActionsTeamComponent {
  @Input()
  data: UserActionsDTO;

  string_to_slug = string_to_slug;

  constructor(public envConfig: EnvService<Config>) {}

  getColor(user: TeamMemberEMARecapDTO) {
    if (user.rank === 0) return null;
    const colors = ['', 'var(--hiji-rank-1)', 'var(--hiji-rank-2)', 'var(--hiji-rank-3)'];
    return colors[user.rank] + ' !important';
  }
}
