import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { L } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, Ic2ToastrService, OpinionService, OpinionType } from 'common';
import { OpinionDataService } from './opinion.data.service';

@Component({
  selector: 'hiji-ask-opinion',
  templateUrl: './ask-opinion.component.html',
  styleUrls: ['./ask-opinion.component.scss'],
})
export class AskOpinionComponent implements OnInit {
  opinionQuestion = null;
  answersValues = [0, 1];
  npsValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  hasAnswerNps: boolean = false;
  loading: boolean = false;

  OpinionType: typeof OpinionType = OpinionType;

  constructor(
    public opinionDataService: OpinionDataService,
    private translate: TranslateService,
    public authService: AuthService,
    private opinionService: OpinionService,
    private ic2ToastrService: Ic2ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.opinionDataService.opinionFODTO == null) {
      this.router.navigate(['/']);
    } else {
      this.opinionDataService.opinionFODTO.listOpinionQuestion = this.opinionDataService.opinionFODTO.listOpinionQuestion.sort(
        (a, b) => Number(a.nps) - Number(b.nps)
      );
    }
  }

  like(index, idOpinionQuestion) {
    const answer = this.opinionDataService.opinionFODTO.listOpinionAnswer.find((a) => a.idOpinionQuestion === idOpinionQuestion);
    answer.value = this.answersValues[index];
    answer.observed = true;
  }

  npsApreciate(index, idOpinionQuestion) {
    if (this.hasAnswerNps == false) {
      this.hasAnswerNps = true;
    }
    const answer = this.opinionDataService.opinionFODTO.listOpinionAnswer.find((a) => a.idOpinionQuestion === idOpinionQuestion);
    answer.value = this.npsValues[index];
    answer.observed = true;
  }
  isAnswered(index, idOpinionQuestion) {
    const answer = this.opinionDataService.opinionFODTO.listOpinionAnswer.find((a) => a.idOpinionQuestion === idOpinionQuestion);
    return answer.value === this.answersValues[index] && answer.observed;
  }
  getNPSAnswer(idOpinionQuestion: number) {
    const answer = this.opinionDataService.opinionFODTO.listOpinionAnswer.find((a) => a.idOpinionQuestion === idOpinionQuestion);
    return answer.value;
  }

  submit() {
    this.loading = true;
    this.opinionService.saveOpinion(this.opinionDataService.opinionFODTO).subscribe(
      (data) => {
        this.ic2ToastrService.showSuccess(this.translate.instant('opinion.list.Merci pour votre avis !'));
        this.router.navigate(['/']);
        L.v(data);
        this.loading = false;
      },
      (err) => {
        L.e(err);
      }
    );
  }
}
