import { Component, Input, OnInit } from '@angular/core';
import { GroupDTO, GroupType } from 'common';

@Component({
  selector: 'hiji-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss'],
})
export class GroupCardComponent implements OnInit {
  @Input() group: GroupDTO;
  GroupType: typeof GroupType = GroupType;

  constructor() {}

  ngOnInit(): void {}
}
