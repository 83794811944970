<a class="text-body" routerLink="/group/create">
  <div class="bg-white border rounded-3">
    <div class="row g-1">
      <div class="col-auto d-flex align-items-center">
        <i class="fa-light fa-user-plus fa-3x text-tertiary p-4 align-icon"></i>
      </div>
      <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">{{ 'group.create-card.Créer un groupe de feedback' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</a>
