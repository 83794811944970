import { Component, Input } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { FormationSessionExpectationsManagerDTO } from 'common';

@Component({
  selector: 'hiji-mif-manager-expectations-card',
  templateUrl: './mif-manager-expectations-card.component.html',
  styleUrls: ['./mif-manager-expectations-card.component.scss'],
})
export class MifManagerExpectationsCardComponent {
  @Input()
  expectation: FormationSessionExpectationsManagerDTO;
  string_to_slug = string_to_slug;

  constructor() {}
}
