<a
  class="text-body"
  [routerLink]="[
    '/formation',
    formationSession.idFormation,
    string_to_slug(formationSession.formationName | ms),
    'session',
    formationSession.idFormationSession,
    string_to_slug(formationSession.formationSessionName),
    formationSession.trainer ? 'trainer' : 'participant'
  ]"
>
  <div class="bg-white border rounded-3 overflow-hidden p-2">
    <div class="row g-1">
      <div class="col-auto align-icon d-flex align-items-center justify-content-center" style="height: 80px">
        <i class="fa-light fa-graduation-cap fa-3x text-tertiary" *ngIf="!formationSession.formationImageExtension"></i>
        <img
          class="rounded-2"
          style="height: 80px"
          [img-preloader]="envConfig.configuration.publicFileUrl + 'formation-' + formationSession.idFormation + '.png'"
          *ngIf="formationSession.formationImageExtension"
        />
      </div>
      <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto" [ngStyle]="{ color: formationSession.colorCodeFormation }">
            <b>{{ formationSession.formationName | ms }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col-auto" [ngStyle]="{ color: formationSession.colorCodeFormationSession }">
            <b>{{ formationSession.formationSessionName }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <small *ngIf="DateUtils.isSameDay(formationSession.minDate, formationSession.maxDate)">{{ formationSession.minDate | localizedDate }}</small>
            <small *ngIf="!DateUtils.isSameDay(formationSession.minDate, formationSession.maxDate)">
              {{
                'global.Du \{\{startDate\}\} au \{\{endDate\}\}'
                  | translate
                    : {
                        startDate: formationSession.minDate | localizedDate,
                        endDate: formationSession.maxDate | localizedDate
                      }
              }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
