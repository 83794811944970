import { Component, Input, OnInit } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { InquiryCreateDTO } from 'common';

@Component({
  selector: 'hiji-inquiry360-create-card',
  templateUrl: './inquiry360-create-card.component.html',
  styleUrls: ['./inquiry360-create-card.component.scss'],
})
export class Inquiry360CreateCardComponent implements OnInit {
  @Input()
  inquiryToCreate: InquiryCreateDTO;
  warningEndDate: boolean = false;
  string_to_slug = string_to_slug;

  constructor() {}

  ngOnInit(): void {
    this.warningEndDate = this.verifWarningEndDate();
  }

  verifWarningEndDate() {
    const newDate = new Date(new Date().setDate(new Date().getDate() + 3));
    return newDate >= this.inquiryToCreate.endDate;
  }
}
