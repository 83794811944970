<a class="text-body" [routerLink]="['/event', event.idFormationEvent, string_to_slug(event.name | ms)]">
  <div class="bg-white border rounded-3">
    <div class="row g-1">
      <div class="col-auto d-flex align-items-center">
        <i class="fa-light fa-calendar-star fa-3x text-tertiary p-4 align-icon"></i>
      </div>
      <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">
            <b>{{ event.name | ms }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <small *ngIf="DateUtils.isSameDay(event.startDate, event.endDate)">{{ event.startDate | localizedDate }}</small>
            <small *ngIf="!DateUtils.isSameDay(event.startDate, event.endDate)">
              {{
                'global.Du \{\{startDate\}\} au \{\{endDate\}\}'
                  | translate
                    : {
                        startDate: event.startDate | localizedDate,
                        endDate: event.endDate | localizedDate
                      }
              }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
