import { Component, Input } from '@angular/core';
import { EnvService } from '@ic2/ic2-lib';
import { Config, ResetPwdService, UserActionsDTO } from 'common';

@Component({
  selector: 'hiji-user-actions-reset-pwd-pincode',
  templateUrl: './user-actions-reset-pwd-pincode.component.html',
  styleUrl: './user-actions-reset-pwd-pincode.component.scss',
})
export class UserActionsResetPwdPincodeComponent {
  @Input()
  data: UserActionsDTO;
  @Input()
  me: boolean = false;
  loading: boolean = false;
  codePin: string = null;

  constructor(
    public env: EnvService<Config>,
    private resetPwdService: ResetPwdService
  ) {}

  generateResetPwdToken() {
    this.loading = true;
    this.resetPwdService
      .generatePinCode(this.data.member.idUser)
      .defaultOnErrorAnd(() => {
        this.loading = false;
      })
      .execute((data) => {
        this.loading = false;
        this.codePin = data;
      });
  }
}
