import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hiji-encourage-picture-card',
  templateUrl: './encourage-picture-card.component.html',
  styleUrls: ['./encourage-picture-card.component.scss'],
})
export class EncouragePictureCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
