<button class="btn btn-primary" (click)="testBadge()">test badge</button>
<button class="btn btn-primary" (click)="testRegister()">testRegister</button>

<div class="container">
  <div class="row">
    <div class="col">
      <input class="form-control" type="text" [(ngModel)]="testSignal" placeholder="label" />
    </div>
    <div class="col">
      <button class="btn btn-primary" (click)="test()">test signal</button>
    </div>
  </div>
  <div class="row">
    @if (show()){
    <div class="col">affiché si true</div>
    }
    <div class="col">
      <button class="btn btn-primary" (click)="show.set(true)">show</button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <hiji-radar-chart [data]="data"></hiji-radar-chart>
    </div>
  </div>
</div>
