import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvService, L } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, Config, HijiBundle, HijiUser, StaticPageService } from 'common';

@Component({
  selector: 'hiji-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss'],
})
export class StaticPageComponent implements OnInit {
  @Input()
  fullPage: boolean = true;
  html: SafeHtml | string;
  hu: HijiUser;
  page: string;
  loading: boolean = true;
  loadingAction: boolean = false;
  backoffice: boolean = false;
  logged: boolean = true;

  constructor(
    private router: Router,
    private staticPageService: StaticPageService,
    private translate: TranslateService,
    private domSanitizer: DomSanitizer,
    public authService: AuthService,
    private env: EnvService<Config>,
    private route: ActivatedRoute
  ) {
    this.page = this.router.url.substr(1);
    if (this.page === 'out/gdpr') {
      this.page = 'gdpr';
      this.logged = false;
    } else this.hu = (<HijiBundle>authService.userBundle.data).hijiUser;
    this.backoffice = this.route.snapshot.data['backoffice'] ?? false;
  }

  ngOnInit(): void {
    this.staticPageService.getPage(this.page).subscribe(
      (data) => {
        this.loading = false;
        if (!(this.translate.currentLang in data.html.obj)) {
          this.html = this.domSanitizer.bypassSecurityTrustHtml(data.html.obj[Object.keys(data.html.obj)[0]]); //default to first one
        } else this.html = this.domSanitizer.bypassSecurityTrustHtml(data.html.obj[this.translate.currentLang]);
      },
      (err) => {
        this.loading = false;
        if (err.isBusinessError())
          this.html = this.domSanitizer.bypassSecurityTrustHtml(
            'Désolé, cette page n\'a pas encoré été rédigée. Merci de la rédiger dans le <a href="' +
              this.env.configuration.boUrl +
              '/configuration/static-page">backoffice</a>'
          );
        else L.e(err);
      }
    );
  }

  accept() {
    this.loadingAction = true;
    this.staticPageService.validateGDPR().subscribe(
      (data) => {
        this.loadingAction = false;
        (<HijiBundle>this.authService.userBundle.data).hijiUser.RGPDValidationdate = new Date();
        (<HijiBundle>this.authService.userBundle.data).hijiUser.RGPDValidated = true;

        if (this.backoffice) {
          this.router.navigate(['/']);
        } else if (!this.env.configuration.hideDiscover) {
          this.router.navigate(['/discover']);
        } else if (localStorage.getItem('redirectUrl') !== null && !localStorage.getItem('redirectUrl').includes('tom-auth')) {
          //protection pour chanel, pas sur qu'elle soit utile
          this.router.navigateByUrl(localStorage.getItem('redirectUrl'));
          localStorage.removeItem('redirectUrl');
        } else {
          this.router.navigate(['/']);
        }
      },
      (err) => {
        this.loadingAction = false;
        L.e(err);
      }
    );
  }

  startAdventure() {
    if (localStorage.getItem('redirectUrl') !== null && !localStorage.getItem('redirectUrl').includes('tom-auth')) {
      this.router.navigateByUrl(localStorage.getItem('redirectUrl'));
      localStorage.removeItem('redirectUrl');
    } else {
      this.router.navigate(['/']);
    }
  }

  updateMessageSeen() {
    this.loadingAction = true;
    this.staticPageService.updateMessageSeen().subscribe(
      (data) => {
        this.loadingAction = false;
        (<HijiBundle>this.authService.userBundle.data).hijiUser.updateMessageSeen = true;
        if (localStorage.getItem('redirectUrl') !== null) {
          this.router.navigateByUrl(localStorage.getItem('redirectUrl'));
          localStorage.removeItem('redirectUrl');
        } else {
          this.router.navigate(['/']);
        }
      },
      (err) => {
        this.loadingAction = false;
        L.e(err);
      }
    );
  }
}
