import { Component, OnInit } from '@angular/core';
import { EnvService, string_to_slug } from '@ic2/ic2-lib';
import { AuthService, Config, HijiBundle, HomeDTO, HomeService } from 'common';

@Component({
  selector: 'hiji-home-skillsup',
  templateUrl: './home-skillsup.component.html',
  styleUrl: './home-skillsup.component.scss',
})
export class HomeSkillsupComponent implements OnInit {
  data: HomeDTO = null;
  loading: boolean = true;
  string_to_slug = string_to_slug;
  encouragePicture: boolean = false;

  constructor(
    private homeService: HomeService,
    private authService: AuthService,
    public envService: EnvService<Config>
  ) {
    this.encouragePicture =
      (<HijiBundle>this.authService.userBundle.data).hijiUser.avatarExtension === null && !envService.configuration.hideHomeEncouragePictureCard;
  }

  ngOnInit(): void {
    this.homeService.getTasks().execute((data) => {
      this.data = data;
      this.loading = false;
    });
  }
}
