<div class="row">
  <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
    <div class="bg-white border rounded-3 p-3">
      <div class="d-flex align-items-center mb-3">
        <i class="fa-light fa-fire fa-2x text-primary p-2 me-2 align-icon"></i>
        <div class="my-fs-md-4 my-fs-2 align-text-bottom">
          {{ 'user-actions.kpi.Indicateurs clés' | translate }}
        </div>
      </div>
      <ng-container *ngFor="let kpi of data">
        <ng-container *ngIf="getResults(kpi).length > 1">
          <div class="row">
            <div class="col">{{ kpi.objective.name | ms }}</div>
            <div class="col fw-bold align-items-center">
              <span [ngbTooltip]="getLastResultTooltip(kpi)">
                {{ getLastResultValue(kpi) | number : '1.0-2' }}
                <ng-container *ngIf="kpi.objective.showAsPercentageInGraph">%</ng-container>
              </span>
              <span [ngbTooltip]="getInfos(kpi)">
                <i class="fa-solid fa-arrow-up-right text-success ms-2" *ngIf="getTendance(kpi) === 'up'"></i>
                <i class="fa-solid fa-arrows-left-right ms-2" *ngIf="getTendance(kpi) === 'flat'"></i>
                <i class="fa-solid fa-arrow-down-right text-danger ms-2" *ngIf="getTendance(kpi) === 'down'"></i>
                <i class="fa-solid fa-calendar mx-2"></i>
                <span class="my-fs-2">{{ getDate(kpi) }}</span>
              </span>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="row mt-3 justify-content-between">
        <div class="col-6">
          <button class="btn btn-primary btn-sm" type="button" (click)="toggleHisto()">
            <i class="fa-light fa-chart-bar me-2"></i>
            {{ 'user-actions.kpi.Graphique KPI' | translate }}
          </button>
        </div>
      </div>
      <div class="row" *ngIf="showHisto">
        <hiji-kpi-stats [data]="data"></hiji-kpi-stats>
      </div>
    </div>
  </div>
</div>
