import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MsPipe } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { SurveyVisualizeDTO, TagManager, TagRadarData } from 'common';

@Component({
  selector: 'hiji-visualize-survey-chart',
  templateUrl: './visualize-survey-chart.component.html',
  styleUrls: ['./visualize-survey-chart.component.scss'],
})
export class VisualizeSurveyChartComponent implements OnInit, OnChanges {
  @Input()
  data: SurveyVisualizeDTO;
  @Input()
  enableOthers: boolean = false;
  hasTeamStats: boolean = false;
  showOthers: boolean = true;
  showAutoObservations: boolean = true;
  showEnseigne: boolean = false;
  showSociete: boolean = false;
  showRegion: boolean = false;
  showEtablissement: boolean = false;
  radarData: TagRadarData[] = [];

  constructor(private msPipe: MsPipe, private translate: TranslateService, private tagManager: TagManager) {}

  ngOnInit(): void {
    this.buildData();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.buildData();
  }
  hasAnyTagsToShow() {
    return this.radarData.some((data) => data.values.length > 0);
  }
  buildData() {
    const data: TagRadarData[] = [];
    if (this.showAutoObservations)
      data.push({
        name: 'Mon observation',
        color: 'var(--hiji-auto-observation)',
        background: true,
        values: this.data.myStats
          .map((stat) => {
            return { tag: this.tagManager.getTag(stat.idTag), stat };
          })
          .filter((stat) => stat.tag.showInRadar)
          .map(({ tag, stat }) => {
            return { axis: this.msPipe.transform(tag.name), data: tag.idTag, color: tag.colorCode, value: stat.avg };
          }),
      });
    if (this.showOthers && this.data.otherStats.length > 0)
      data.push({
        name: 'Ensemble des collaborateurs',
        color: 'var(--hiji-manager)',
        background: true,
        values: this.data.otherStats
          .map((stat) => {
            return { tag: this.tagManager.getTag(stat.idTag), stat };
          })
          .filter((stat) => stat.tag.showInRadar)
          .map(({ tag, stat }) => {
            return { axis: this.msPipe.transform(tag.name), data: tag.idTag, color: tag.colorCode, value: stat.avg };
          }),
      });
    if (this.showEnseigne)
      data.push({
        name: 'Enseigne',
        color: 'var(--hiji-enseigne)',
        background: true,
        values: this.data.enseigneStats
          .map((stat) => {
            return { tag: this.tagManager.getTag(stat.idTag), stat };
          })
          .filter((stat) => stat.tag.showInRadar)
          .map(({ tag, stat }) => {
            return { axis: this.msPipe.transform(tag.name), data: tag.idTag, color: tag.colorCode, value: stat.avg };
          }),
      });
    if (this.showSociete)
      data.push({
        name: 'Societe',
        color: 'var(--hiji-societe)',
        background: true,
        values: this.data.societeStats
          .map((stat) => {
            return { tag: this.tagManager.getTag(stat.idTag), stat };
          })
          .filter((stat) => stat.tag.showInRadar)
          .map(({ tag, stat }) => {
            return { axis: this.msPipe.transform(tag.name), data: tag.idTag, color: tag.colorCode, value: stat.avg };
          }),
      });
    if (this.showRegion)
      data.push({
        name: 'Région',
        color: 'var(--hiji-region)',
        background: true,
        values: this.data.regionStats
          .map((stat) => {
            return { tag: this.tagManager.getTag(stat.idTag), stat };
          })
          .filter((stat) => stat.tag.showInRadar)
          .map(({ tag, stat }) => {
            return { axis: this.msPipe.transform(tag.name), data: tag.idTag, color: tag.colorCode, value: stat.avg };
          }),
      });
    if (this.showEtablissement)
      data.push({
        name: 'Etablissement',
        color: 'var(--hiji-etablissement)',
        background: true,
        values: this.data.etablissementStats
          .map((stat) => {
            return { tag: this.tagManager.getTag(stat.idTag), stat };
          })
          .filter((stat) => stat.tag.showInRadar)
          .map(({ tag, stat }) => {
            return { axis: this.msPipe.transform(tag.name), data: tag.idTag, color: tag.colorCode, value: stat.avg };
          }),
      });

    this.radarData = data.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
  }

  isInFuture(date: Date) {
    if (date === null) return false;
    const copiedDate = new Date(date.getTime());
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    copiedDate.setHours(0, 0, 0, 0);
    return copiedDate > now;
  }
}
