<div class="container-fluid bg-light-30p h-100 d-flex flex-column fit-height">
  <div class="row" *ngIf="loading">
    <div class="col">
      <i class="fa fa-spinner spinning"></i>
    </div>
  </div>
  <ng-container *ngIf="!loading">
    <div class="container-fluid overflow-auto flex-grow-1 mt-1">
      <div class="row mt-3" *ngFor="let group of groups">
        <hiji-group-card class="pointer" [group]="group" (click)="selectedGroup(group)"></hiji-group-card>
      </div>
      <div class="row mt-3">
        <div class="col">
          <a class="btn btn-primary" routerLink="/group/create">{{ 'group.list.Créer une équipe' | translate }}</a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
