<div class="row" *ngIf="(data.isInMyGlobalTeam || me) && data.userActionsEMADTO.team.length > 0">
  <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
    <a class="text-body pointer" [routerLink]="['/teamboard', 'list', data.member.idUser, string_to_slug(data.member.firstName + '-' + data.member.lastName)]">
      <div class="bg-white border rounded-3 p-2 d-flex align-items-center">
        <i class="fa-light fa-users-rectangle fa-2x text-primary p-2 align-icon"></i>
        <span class="my-fs-md-4 align-text-bottom">
          {{ "teamboard.user-actions.Consulter les précédents dashboard d'équipe" | translate }}
        </span>
      </div>
    </a>
  </div>
</div>
