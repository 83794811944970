@if (env.configuration.resetPasswordViaPinCode && data.isInMyGlobalTeam && !me) {
  <div class="row">
    <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
      @if (codePin === null) {
        <a class="text-body pointer" [routerLink]="null" (click)="generateResetPwdToken()">
          <div class="bg-white bg-light-30p-hover border rounded-3 p-2 d-flex align-items-center">
            <i class="fa-light fa-unlock fa-2x text-primary p-2 align-icon"></i>
            <span class="my-fs-md-4 align-text-bottom">
              {{ 'user-actions.reset-pwd-pincode.Générer un code de réinitialisation de mot de passe' | translate }}
            </span>
          </div>
        </a>
      } @else {
        <div class="bg-white border rounded-3 p-2 d-flex align-items-center">
          <i class="fa-light fa-unlock fa-2x text-primary p-2 align-icon"></i>
          <span class="my-fs-md-4 align-text-bottom">
            {{ 'user-actions.reset-pwd-pincode.Code de réinitialisation' | translate }}:
            <span class="fw-bold my-fs-4">{{ codePin }}</span>
          </span>
        </div>
      }
    </div>
  </div>
}
