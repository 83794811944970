<hiji-wavy-container>
  <div class="container bg-white rounded shadow-2 py-3 m-3 mx-sm-auto" style="z-index: 10">
    <div class="row">
      <div class="col">
        <h3>{{ "kiosk-login.Saississez votre code de connexion pour répondre à l'enquête" | translate }}</h3>
      </div>
    </div>
    <div class="row mt-3" *ngIf="error">
      <div class="col">
        <ul class="errors">
          <li>{{ 'kiosk-login.Le code de connexion semble être invalide' | translate }}</li>
        </ul>
      </div>
    </div>
    <div class="row mt-3 justify-content-center">
      <div class="col h-200px" *ngIf="!loading">
        <input
          class="form-control py-3 text-center big-text"
          style="height: 100% !important"
          type="text"
          [(ngModel)]="code"
          (ngModelChange)="check()"
          (input)="code = code.toUpperCase()"
        />
      </div>
      <div class="col-auto" *ngIf="loading">
        <i class="fa-light fa-spinner spinning big-text"></i>
      </div>
    </div>
  </div>
</hiji-wavy-container>
